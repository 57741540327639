/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import "react-image-lightbox/style.css"

const Layout = props => {
  const { children, width } = props
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  console.log({ children })
  console.log({ props })
  // <Header siteTitle={data.site.siteMetadata.title} />

  return (
    <>
      <Header siteTitle={"M.Paccione Design & Development"} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: width ? width : 960,
          padding: width ? `0 70px` : `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
